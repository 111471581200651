import * as React from "react";

const Microcement = () => {
  return (
    <div
      style={{
        maxWidth: "800px",
        margin: "0 auto",
        padding: "30px",
        minHeight: "55vh",
      }}
    >
      <h1
        style={{ textAlign: "center", fontSize: "36px", marginBottom: "30px" }}
      >
        Microcement
      </h1>
      <p style={{ fontSize: "18px", lineHeight: "1.5" }}>
        This is a modern type of plastering that is made from a mixture of
        cement, fine aggregates and pigments. It gives a smooth finish and can
        be used to create an industrial look and feel.
      </p>
    </div>
  );
};

export default Microcement;
